import React, { Component } from "react";
import { connect } from "react-redux";
import {
  getUIContent,
  trackParticipants,
  updateParticipant,
  attemptRouterChainSurvey,
  vendorRedirectUpdate,
  updateClientAppRedirectUpdate,
  endRouterChainSurvey,
  getRedirectUrlForUserSurveyAttempt,
  checkReRoutingUser
} from "../../actions/terminateAction";
import "./style.css";
import "./main.css";
import { FormattedMessage } from "react-intl";
import appConfig from "../../api/apiConfig";
import { getUserSurveyList } from "../elements/userSurveyList";
import OverQuotaImg from "../../Image/terminateCross.png";
import '../terminateFolder/terminate.css'

class StudyTerminate extends Component {
  constructor(props) {
    super(props);
    this.queryString = window.location.search;
    this.queryStringHref = window.location.href;
    this.allQueryStrings = window.location.search;
    this.queryOrigion = window.location.origin;
    this.queryPathname = window.location.pathname;
    let origionNotHttp = this.queryOrigion.replace(/(^\w+:|^)\/\//, "");
    this.origionNotHttp1 =
      origionNotHttp + this.queryPathname + this.allQueryStrings;

    if (this.queryString !== "") {
      let searchParams = getUrlParam("UID", "Empty");
      if(searchParams === "xxxx"){
        searchParams = getUrlParam("mid", "Empty");
        if(searchParams === "Empty"){
          searchParams = getUrlParam("pid", "Empty");
          if(searchParams === "Empty"){
            searchParams = getUrlParam("uid", "Empty");
          }
        }
      } else if (searchParams === "Empty") {
        searchParams = getUrlParam("uid", "Empty");
      }
      if (searchParams && searchParams !== "Empty") {
        this.uid = searchParams;
        this.sid = "sid";
      }
    }

    this.state = {
      uid: this.uid,
      sid: this.sid,
      terminateUrlMainSurvey: "",
      redirectedUrl: this.queryStringHref,
      isPrStudy: false,
      headerMessage: "",
      bodyMessage: "",
      participantUpdateData: {},
      isUpdateParticipantCalled: false,
      isVendorAppRedirectCalled: false,
      isClientAppRedirect: false,
      pleaseWaitEnabled: false,
      reRoutingCalled : false,
      noWorriesTerminate: false,
      reRoutingWait:false,
      redurectUrlForRouting : '',
      vendorRedirectUrl:''
      
    };
    this.timer = null;
    this.updateParticipant = this.updateParticipant.bind(this);
    this.updateVendorRedirect = this.updateVendorRedirect.bind(this);
    this.onMultipleAttemptClick = this.onMultipleAttemptClick.bind(this);
    this.checkRedirectUrlForRouting = this.checkRedirectUrlForRouting.bind(this);
    this.participantReRoutingRedirect = this.participantReRoutingRedirect.bind(this);
    

  }

  componentWillMount() {
    this.props.dispatch(trackParticipants(window.location.href));
    if (this.queryString !== "") {
      let searchParams = getUrlParam("UID", "Empty");
      if (searchParams === "Empty") {
        searchParams = getUrlParam("uid", "Empty");
      }
      if (searchParams && searchParams !== "Empty") {
        this.setState({reRoutingWait: true});
        this.updateParticipant();
      } else {
        alert("You are requesting with bad parameters.");
      }
    }
  }
  componentDidUpdate(prevProps, prevState) {
    // If noWorriesTerminate has changed and is now true, start the timer
    if (this.state.noWorriesTerminate && this.state.noWorriesTerminate !== prevState.noWorriesTerminate) {
      this.startLeaveTimer();
    }
  }

  componentWillUnmount() {
    this.clearLeaveTimer();
  }

  startLeaveTimer = () => {
    // Ensure any previous timer is cleared before starting a new one
    this.clearLeaveTimer();
    this.timer = setTimeout(() => {
      this.onClickLeave(); // Automatically trigger Leave after 10 seconds
    }, 10000);
  };

  clearLeaveTimer = () => {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  };


  updateParticipant() {
    let participantData = {};
    participantData.sid = this.state.sid;
    participantData.status = 3;
    participantData.uid = this.state.uid;
    participantData.redirectedUrl = this.origionNotHttp1;
    participantData.checkLoi = false;

    this.setState({ participantUpdateData: participantData });
    //this.props.dispatch(getUIContent(participantData));
    this.props.dispatch(updateParticipant(participantData));
  }

  onClickNoRedirectToVendor(data) {
    this.updateVendorRedirect(data.redirectUrl);
  }

  onClickYesAttemptRouterChain(data) {
    let dataToPass = {
      uid: data.UID,
      tid: data.TID,
      var3: data.var3 === "" ? "-1" : data.var3,
      landingUrl: window.location.href
    };
    this.props.dispatch(attemptRouterChainSurvey(dataToPass));
    this.setState({
      pleaseWaitEnabled: true
    });
  }

  onClickYesAttemptRouterSurvey(data) {
    let url = appConfig.participantUrl + "?" + data.routerParticipantUrl;
    window.location.href = url;
  }

  onClickNoEndRouterChain(data) {
    this.props.dispatch(endRouterChainSurvey(data.originPID));
    this.setState({
      pleaseWaitEnabled: true
    });
  }

  onMultipleAttemptClick(item, clientType, isFirstAttempt, parentPID) {
    let dataToPass = {};

    if (clientType == 11 || clientType == 2) {
      dataToPass.clientProjectId = item.ProjectId;
      dataToPass.LOI = item.SurveyLength;
      dataToPass.IR = item.Ir;
      dataToPass.CPI = item.MemberReward;
      dataToPass.PID = parentPID;
      dataToPass.apiType = clientType;
      dataToPass.isFirstAttempt = isFirstAttempt;
      dataToPass.surveyUrl = item.SurveyUrl;
      dataToPass.landingUrl = this.origionNotHttp1;
    }
    this.props.dispatch(getRedirectUrlForUserSurveyAttempt(dataToPass));
  }

  updateVendorRedirect(url) {
    setTimeout(function() {
      window.location.href = url;
    }, 1000);
    if (this.state.isVendorAppRedirectCalled === false) {
      this.setState({ isVendorAppRedirectCalled: true });
      this.props.dispatch(
        vendorRedirectUpdate(this.state.participantUpdateData, url)
      );
    }
  }

  participantReRoutingRedirect(url){
    if(url.includes('https://')){
      window.location.href = url;
    }else{
      url = "https://" +url;
      window.location.href = url
    }
   
  }

  updateClientAppRedirect(data, url) {
    setTimeout(function() {
      window.location.href = url;
    }, 1000);
    if (this.state.isClientAppRedirect === false) {
      this.setState({ isClientAppRedirect: true });
      this.props.dispatch(
        updateClientAppRedirectUpdate(data, url)
      );
    }
  }
  checkRedirectUrlForRouting(url){
    let pid = this.state.uid;
    this.setState({vendorRedirectUrl : url})
    this.setState({reRoutingCalled : true})
    if(pid){
      this.props.dispatch(checkReRoutingUser(pid,url));
    }else{
      this.updateVendorRedirect(url);
    }
  }
  
  onClickLeave = () => {
    if(this.state.vendorRedirectUrl){
      this.updateVendorRedirect(this.state.vendorRedirectUrl);
    }
  };

  onClickContinue = () => {
    this.clearLeaveTimer();
    if (this.state.noWorriesTerminate && this.state.redurectUrlForRouting) {
      this.participantReRoutingRedirect(this.state.redurectUrlForRouting);
    }
  };
  componentWillReceiveProps(nextProps) {
    if (
      nextProps.endRouterChainSurvey &&
      nextProps.endRouterChainSurvey.success
    ) {
      if (nextProps.endRouterChainSurvey.isRedirect) {
        this.updateVendorRedirect(
          nextProps.endRouterChainSurvey.redirectUrl
        );
      } else {
        this.setState({ bodyMessage: nextProps.endRouterChainSurvey.message });
      }
    } else if (
      nextProps.attemptRouterChainSurvey &&
      nextProps.attemptRouterChainSurvey.success
    ) {
      // check
      let chainSurveyReport = nextProps.attemptRouterChainSurvey;
      if (chainSurveyReport.isRedirect) {
        window.location.href = chainSurveyReport.redirectUrl;
      } else {
        this.setState({ bodyMessage: nextProps.updateParticipantResp.message });
      }
    } else if (
      nextProps.updateParticipantResp &&
      nextProps.updateParticipantResp.success &&
      nextProps.updateParticipantResp.holdRedirect == true
    ) {
      debugger;
      // if (nextProps.updateParticipantResp.isRedirect && !nextProps.updateParticipantResp.isRouterSurveyChainAvailable) {
      //     this.updateVendorRedirect(nextProps.updateParticipantResp.redirectUrl);
      // }
    } else if (
      nextProps.updateParticipantResp &&
      nextProps.updateParticipantResp.success &&
      nextProps.updateParticipantResp.isRouterChainEnabled == false
    ) {
      debugger;
      if (
        nextProps.updateParticipantResp.isRedirect &&
        !nextProps.updateParticipantResp.isRouterSurveyChainAvailable
      ) {
        // Check isFirstPartyRedirect 
        if(nextProps.updateParticipantResp.hasOwnProperty("isFirstPartyRedirect")
        && nextProps.updateParticipantResp.isFirstPartyRedirect == true){
          this.setState({vendorRedirectUrl: nextProps.updateParticipantResp.redirectUrl});
          this.updateClientAppRedirect(nextProps.updateParticipantResp,
            nextProps.updateParticipantResp.redirectUrl
          );
        } else {
          if(!this.state.reRoutingCalled){
            this.checkRedirectUrlForRouting(nextProps.updateParticipantResp.redirectUrl);
          }
         
          // this.updateVendorRedirect(
          //   nextProps.updateParticipantResp.redirectUrl
          // );
        }
      }
    } else if (
      nextProps.updateParticipantResp &&
      nextProps.updateParticipantResp.success &&
      nextProps.updateParticipantResp.isRouterChainEnabled == false &&
      nextProps.updateParticipantResp.isRedirect == true
    ) {
     // Check isFirstPartyRedirect 
     if(nextProps.updateParticipantResp.hasOwnProperty("isFirstPartyRedirect")
        && nextProps.updateParticipantResp.isFirstPartyRedirect == true){
          this.setState({vendorRedirectUrl: nextProps.updateParticipantResp.redirectUrl});
          this.updateClientAppRedirect(nextProps.updateParticipantResp,
            nextProps.updateParticipantResp.redirectUrl
          );
        } else {
          // this.updateVendorRedirect(
          //   nextProps.updateParticipantResp.redirectUrl
          // );
          if(!this.state.reRoutingCalled){
            this.checkRedirectUrlForRouting(nextProps.updateParticipantResp.redirectUrl);
          }
        }
      // this.updateVendorRedirect(nextProps.updateParticipantResp.redirectUrl);
    } else if (
      nextProps.updateParticipantResp &&
      nextProps.updateParticipantResp.success &&
      !nextProps.updateParticipantResp.hasOwnProperty("isRouterChainEnabled") &&
      nextProps.updateParticipantResp.isRedirect == true
    ) {
     // Check isFirstPartyRedirect
     if(nextProps.updateParticipantResp.hasOwnProperty("isFirstPartyRedirect")
        && nextProps.updateParticipantResp.isFirstPartyRedirect == true){
          this.setState({vendorRedirectUrl: nextProps.updateParticipantResp.redirectUrl});
          this.updateClientAppRedirect(nextProps.updateParticipantResp,
            nextProps.updateParticipantResp.redirectUrl
          );
        } else {
          if(!this.state.reRoutingCalled){
            this.checkRedirectUrlForRouting(nextProps.updateParticipantResp.redirectUrl);
          }
         // block this code for re routing
          // this.updateVendorRedirect(
          //   nextProps.updateParticipantResp.redirectUrl
          // );
        }
      //this.updateVendorRedirect(nextProps.updateParticipantResp.redirectUrl);
    } else if( nextProps.updateParticipantResp && nextProps.updateParticipantResp.success == false){
     this.setState({reRoutingWait: false});
    }
    else if(nextProps.updateParticipantRouting){
      if(nextProps.updateParticipantRouting.success){
        debugger
        this.setState({reRoutingWait: false,noWorriesTerminate: true,redurectUrlForRouting : nextProps.updateParticipantRouting.redirectUrl});
       // this is call after they click on continue button
      //  this.participantReRoutingRedirect(nextProps.updateParticipantRouting.redirectUrl)
      }else{
        // this.participantReRoutingRedirect(nextProps.updateParticipantRouting.redirectUrl)
        this.setState({reRoutingWait: false});
        this.updateVendorRedirect(nextProps.updateParticipantRouting.redirectUrl);
      }
    } else {
      this.setState({ bodyMessage: nextProps.updateParticipantResp.message });
    }

    if (nextProps.uiContent && nextProps.uiContent.success) {
      if (this.state.isUpdateParticipantCalled === false) {
        this.setState({
          headerMessage: nextProps.uiContent.headerMessage,
          bodyMessage: nextProps.uiContent.message,
          isUpdateParticipantCalled: true
        });
      }
    }
    
  }

  render() {
    let showTerminatePage = "";
    if (this.state.pleaseWaitEnabled) {
      showTerminatePage = (
        <main>
          <div className="round">
            <div className="round-circle"></div>
            <div className="round-caption">
              <h2 className="modal-title ">
                <FormattedMessage id="app.msg.pleaseWait" />
              </h2>
            </div>
          </div>
          <div className="right-image">
            <img
              src={require("../../Image/student-experience.png")}
              alt="study"
              class=""
            />
          </div>
        </main>
      );
    } else if (
      this.props.updateParticipantResp &&
      this.props.updateParticipantResp.hasOwnProperty("isMultipleWindow") &&
      this.props.updateParticipantResp.isMultipleWindow === true
    ) {
      showTerminatePage = getUserSurveyList(
        this.props.updateParticipantResp.userSurveys,
        this.props.updateParticipantResp.apiType,
        this.props.updateParticipantResp.isFirstAttempt,
        this.onMultipleAttemptClick,
        this.props.updateParticipantResp.PID
      );
    } else if (
      this.props.updateParticipantResp &&
      this.props.updateParticipantResp.holdRedirect
    ) {
      showTerminatePage = (
        <main>
          <div className="round">
          <p style={{fontsize : "142%"}}>
                <FormattedMessage id="app.msg.redirected" />
              </p>
            <div className="round-circle"></div>
            <div className="round-caption">
              <h2 className="modal-title ">
                <FormattedMessage id="app.msg.ternimate" />
              </h2>
              <p>
                <FormattedMessage id="app.mgs.content" />
              </p>
            </div>
          </div>
          <div className="right-image">
            <img
              src={require("../../Image/student-experience.png")}
              alt="study"
              class=""
            />
          </div>
        </main>
      );
    } else if (
      this.props.updateParticipantResp &&
      this.props.updateParticipantResp.isRouterChainEnabled
    ) {
      showTerminatePage = (
        <main>
          <div className="round">
            <div className="round-circle"></div>
            <div className="round-caption">
              <p>
                <FormattedMessage id="app.mgs.contentRouterChain" />
              </p>
              <button
                className="btn btn-primary"
                style={{ marginRight: "16px", width: "70px", height: "40px" }}
                onClick={this.onClickYesAttemptRouterSurvey.bind(
                  this,
                  this.props.updateParticipantResp
                )}
              >
                Yes
              </button>
              <button
                className="btn btn-primary"
                onClick={this.onClickNoEndRouterChain.bind(
                  this,
                  this.props.updateParticipantResp
                )}
              >
                No
              </button>
            </div>
          </div>
          <div className="right-image">
            <img
              src={require("../../Image/student-experience.png")}
              alt="study"
              class=""
            />
          </div>
        </main>
      );
    } else if (
      this.props.updateParticipantResp &&
      this.props.updateParticipantResp.isRouterSurveyChainAvailable
    ) {
      showTerminatePage = (
        <main>
          <div className="round">
            <div className="round-circle"></div>
            <div className="round-caption">
              <h2 className="modal-title ">
                <FormattedMessage id="app.msg.congrats" />
              </h2>
              <p>
                <FormattedMessage id="app.mgs.contentRouterChain" />
              </p>
              <button
                className="btn btn-primary"
                style={{ marginRight: "16px", width: "70px", height: "40px" }}
                onClick={this.onClickYesAttemptRouterChain.bind(
                  this,
                  this.props.updateParticipantResp
                )}
              >
                Yes
              </button>
              <button
                className="btn btn-primary"
                onClick={this.onClickNoRedirectToVendor.bind(
                  this,
                  this.props.updateParticipantResp
                )}
              >
                No
              </button>
            </div>
          </div>
          <div className="right-image">
            <img
              src={require("../../Image/student-experience.png")}
              alt="study"
              class=""
            />
          </div>
        </main>
      );
    }  else if (
      this.state.noWorriesTerminate
    ) {
      showTerminatePage = (
        <main>
          <div className="overquota-container">
            <div className="card-container">
              <div className="card-header">
                <div className="image-container">
                  <img
                    src={OverQuotaImg}
                    alt="Survey Illustration"
                    className="overquota-img"
                  />
                </div>
              </div>
              <div className="card-content">
                <h2 className="title"> <FormattedMessage id="app.msg.oops" /></h2>
                <p className="description">
                  <span className=""><FormattedMessage id="app.msg.No worries" /> </span><FormattedMessage id="app.msg.chance"/>
                  <FormattedMessage id="app.msg.explore" /> <br /><span><FormattedMessage id="app.msg.reward" /></span>
                </p>
                <p className="description1">
                  <span className=""><FormattedMessage id="app.msg.No worries" /></span><FormattedMessage id="app.msg.chance"/><br />
                  <FormattedMessage id="app.msg.explore" /> <span><FormattedMessage id="app.msg.mobilereward" /> <br/><FormattedMessage id="app.msg.earnreward"/></span>
                </p>
                <div className="button-container">
                  <button className="button-left"  onClick={this.onClickLeave} ><FormattedMessage id="app.msg.leave"/></button>
                  <button className="button-right" onClick={this.onClickContinue}><FormattedMessage id="app.msg.continue"/></button>
                </div>
              </div>
            </div>
          </div>
        </main>
      );
    } else if(this.state.reRoutingWait){
      showTerminatePage = (
        <main>
          <div className="round">
            <div className="round-circle"></div>
            <div className="round-caption">
              <h2 className="modal-title ">
                <FormattedMessage id="app.msg.pleaseWait" />
              </h2>
            </div>
          </div>
          <div className="right-image">
            <img
              src={require("../../Image/student-experience.png")}
              alt="study"
              class=""
            />
          </div>
        </main>
      );
    }
    else {
      showTerminatePage = (
        <main>
          <div className="round">
            <p style={{fontsize : "142%"}}>
                <FormattedMessage id="app.msg.redirected" />
              </p>
            <div className="round-circle"></div>
            <div className="round-caption">
              <h2 className="modal-title ">
              <FormattedMessage id="app.msg.ternimate" />
              </h2>
              <p>
                <FormattedMessage id="app.mgs.content" />
              </p>
            </div>
          </div>
          <div className="right-image">
            <img
              src={require("../../Image/student-experience.png")}
              alt="study"
              class=""
            />
          </div>
        </main>
      );
    }

    return <div className="animate-bottom">{showTerminatePage}</div>;
  }
}

const mapStateToProps = state => {
  // console
  return {
    updateParticipantResp: state.successStore.updateParticipantResp,
    uiContent: state.successStore.uiContent,
    attemptRouterChainSurvey: state.successStore.attemptRouterChainSurvey,
    endRouterChainSurvey: state.successStore.endRouterChainSurvey,
    updateParticipantRouting : state.successStore.updateParticipantRouting
  };
};

function getUrlParam(parameter, defaultvalue) {
  var urlparameter = defaultvalue;
  if (window.location.href.indexOf(parameter) > -1) {
    urlparameter = getUrlVars()[parameter];
  }
  return urlparameter;
}

function getUrlVars() {
  var vars = {};
  var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(
    m,
    key,
    value
  ) {
    vars[key] = value;
  });
  return vars;
}

export default connect(mapStateToProps)(StudyTerminate);
